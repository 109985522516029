import { dev } from '$app/environment'
import { Replay, init } from '@sentry/sveltekit'

export const sentryServerInit = () => {
	init({
		dsn: 'https://26bc95842d8e12b36208eb8154e6c61a@o4505716071530496.ingest.sentry.io/4505721839550464',
		environment: import.meta.env.MODE,
		tracesSampleRate: 1.0
	})
}

export const sentryClientInit = () => {
	init({
		dsn: 'https://26bc95842d8e12b36208eb8154e6c61a@o4505716071530496.ingest.sentry.io/4505721839550464',
		environment: import.meta.env.MODE,
		tracesSampleRate: 1.0,
		integrations: [new Replay()],
		replaysSessionSampleRate: dev ? 1.0 : 0.1,
		replaysOnErrorSampleRate: 1.0
	})
}
