import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0];

export const dictionary = {
		"/(main)": [3,[2]],
		"/(main)/about-us": [4,[2]],
		"/(main)/about-us/awards": [5,[2]],
		"/(main)/about-us/employment": [6,[2]],
		"/(main)/about-us/guarantee": [7,[2]],
		"/(main)/about-us/vendors": [8,[2]],
		"/(no-layout)/api/schedule": [16],
		"/(main)/areas-we-serve": [9,[2]],
		"/(main)/areas-we-serve/[[state]]/[area]": [10,[2]],
		"/(no-layout)/coupon/[code]": [17],
		"/(main)/services": [11,[2]],
		"/(main)/services/[service]": [12,[2]],
		"/(main)/tips/glossary": [13,[2]],
		"/(main)/tips/safety": [14,[2]],
		"/(main)/tips/safety/[article]": [15,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';